import React, { Fragment, useState, useEffect, useRef } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from './loaderred.gif';
import download from './download.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
const { config } = require('./api/api');

function MyLounges() {

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [vendorKey, setVendorkey] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [loungeOrderedDetails, setLoungeorderedDetails] = useState('');
  const [policyLink, setPolicyLink] = useState(null);
  const [policy_no, setPolicyNo] = useState(null);
  const [contract_id, setcontractsID] = useState('');
  // const [showUploadModal, setShowUploadModal] = useState(false);

  const [loungescoupon, setLoungesToken] = useState('');

  const [passportFile, setPassportFile] = useState(null);
  const [questionnaireFile, setQuestionnaireFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileChoosen,setFileChoosen]=useState(true);
  const [userData,setUserData]=useState(null);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages]=useState(0);
  const [profile, setProfile] = useState(null);
  const [indexOfFirstItem, setindexOfFirstItem]= useState(0);
  const [indexOfLastItem, setIndexOfLastItem]= useState(0);
  const [currentItems, currentItemsdata]= useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const tableRef=useRef(null);
  const [pageChanged,setPageChanged]=useState(false);

  const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

  useEffect(() => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('order_id');
    setPolicyNo(policy_no);
  }, []);

    useEffect(() => {
      if (showUploadModal) {
        document.body.style.overflow = 'hidden'; 
      } else {
        document.body.style.overflow = 'auto';
      }
      
      return () => {
        document.body.style.overflow = 'auto'; 
      };
    }, [showUploadModal]);


  useEffect(() => {
    const createOrganization = async () => {
      try {
        const postvData = { organization_name: "Superjet" };

        const response = await fetch(base_url + '/create_organization', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postvData),
        });
        const data = await response.json();
        setVendorkey(data.result[0].vendor_key);
      } catch (error) {
        console.error('Error posting data:', error);
      }
    };

    if (!vendorKey) {
      createOrganization();
    }
  }, [vendorKey, base_url]);

  

  useEffect(()=>{
    const getUserData=Cookies.get("userData");
    if(getUserData!==null&&getUserData!==undefined&&getUserData!==""){
      const parseUserData=JSON.parse(getUserData);
      setUserData(parseUserData);
    }
  },[]);

  const getLoungesOrderDetails=async()=>{
    const user_token = {token: userData.access_token};
    setLoading(true);
    try{
     const response =await fetch(`${base_url}/get_lounge_order_details`,{
      method:"POST",
      headers:{
        "Authorization":`Bearer ${vendorKey}`,
        "Content-Type":"application/json"
      },
      body:JSON.stringify(user_token)
     })
     if(response.ok){
      setLoading(false);
     const data= await response.json();
     if(data.message==="success"){
         setLoungeorderedDetails(data.result);
          setProfile(data)
          const totalPagesCount = Math.ceil(data.result.length / itemsPerPage);
        setTotalPages(totalPagesCount);
        calculatePagination(currentPage,data.result);
     }
     }
    }
    catch(err){
      setLoading(false);
      console.log("getting lounge order details ",err);
    }
  }
useEffect(()=>{
  if((vendorKey!==""&&vendorKey!==null&&vendorKey!==undefined)&&
  (userData!==""&&userData!==null&&userData!==undefined)){
    getLoungesOrderDetails();
  }
},[vendorKey,userData]);


 useEffect(()=>{
    if(pageChanged && tableRef.current){
  tableRef.current.scrollIntoView({ behavior: "smooth"});
      }
      else{
        console.log("element not found");
      }
      return ()=>{
        setPageChanged(false);
      }
  },[pageChanged]);

  const calculatePagination = (page,list) => {
    // debugger
    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setIndexOfLastItem(indexOfLastItem);
    setindexOfFirstItem(indexOfFirstItem);
    const currentItem = list.slice(indexOfFirstItem, indexOfLastItem);
    currentItemsdata(currentItem);
  };
  
  const handleNextPage = () => {
    // debugger
    setCurrentPage((prevPage) => {
      const newPage = prevPage < totalPages ? prevPage + 1 : prevPage;
      if(loungeOrderedDetails.length > 0){
        calculatePagination(newPage,profile.result);
      }else{
        calculatePagination(newPage,loungeOrderedDetails);
      }
      return newPage;
    });
    setPageChanged(true);
  };
  
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage > 1 ? prevPage - 1 : prevPage;
      if(loungeOrderedDetails.length > 0){
        calculatePagination(newPage,profile.result);
      }else{
        calculatePagination(newPage,loungeOrderedDetails);
      }
      // calculatePagination(newPage,insuranceData);
      return newPage;
    });
    setPageChanged(true);
  };


  const handleCouponDownload = async (order_detail_id,order_id) => {
    // debugger
    var quotation_data = {
      orderItemId: order_detail_id,
      orderId: order_id,
    };
  
    if (vendorKey !== "") {
      setLoading(true);
      try {
        const response = await fetch(base_url + "/generate_coupon", {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${vendorKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(quotation_data),
          responseType: "blob", // Fetch response as a binary file
        });
  
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }
  
        // Convert response to blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
  
        // Create a download link
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "order_coupon.pdf"); // Ensure correct file type
        document.body.appendChild(link);
        link.click();
        setLoading(false);
  
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
  
      } catch (error) {
        setLoading(false);
        console.error("Error generating quotation PDF:", error);
      }
    }
  };
  

      useEffect(() => {
        if (showUploadModal) {
          document.body.style.overflow = 'hidden'; 
        } else {
          document.body.style.overflow = 'auto';
        }
        
        return () => {
          document.body.style.overflow = 'auto'; 
        };
      }, [showUploadModal]);


  const isValidUrl = (url) => {
    return pattern.test(url);
  };

  return (



    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5" ref={tableRef} >{t('MY')} {t('LOUNGES')}</h3>
          </div>
        </div>


      </div>


      
      <div className="features_section layout_padding my-1">
       
         <div className="container-fluid" style={{maxWidth:"1500px"}}>
          <div className="row g-4 justify-content-center">

            <div className="col-12 col-md-11 wow fadeInUp" data-wow-delay="0.2s" style={{ overflowX: 'auto' }}>

              <br />      

                <table className="table table-bordered mt-3" >
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>{t("FirstName")}</th>
                      <th>{t("Last Name")}</th>
                      <th>{t("Email")}</th>
                      <th>{t("Visit Date")}</th>
                      <th>{t("Airport Name")}</th>
                      <th>{t("Terminal")}</th>
                      <th>{t("Payment status")}</th>
                      <th>{t("Paxtype")}</th>
                      <th>{t("Coupon")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loungeOrderedDetails.length === 0 ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          {t("No")} {t("Records")} {t("Found")}
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((prof, index) => (
                        <tr key={index}>
                          <td>{indexOfFirstItem+index + 1}</td>
                          <td>{t(prof.first_name)}</td>
                          <td>{t(prof.last_name)}</td>
                          <td>{prof.order_data[0].email}</td> {/*  Email shouldn't be translated */}
                          <td>{format(new Date(prof.visit_date), "dd/MM/yyyy")}</td>
                          <td>{t(prof.airport_name)}</td>
                          <td>{t(prof.terminal)}</td>
                          <td>{t(prof.payment_status)}</td>
                          <td>{t(prof.pax_type)}</td>
                          <td>
                          {
                            prof.payment_status == "success"?
                            <button
                              variant="primary"
                              className="btn btn-primary rounded-pill text-white py-1 px-2"
                              onClick={() => handleCouponDownload(prof.order_detail_id,prof.order_id)} 
                            >
                              {t("Download")}
                            </button>
                            :
                            <button 
                              variant="primary"
                              className="w-100 btn btn-primary rounded-pill text-white py-1 px-2"
                              // onClick={() => } 
                            >
                              {t("-")}
                            </button>
                          }
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
   
<br/>


            
            
          </div>
          </div>
          <div className="row ">
          <div className='col-md-1'></div>
          <div className="col-12 col-md-11">
          {totalPages === 0 ? <div></div> : <div className="pagination-controls-new">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  ‹
                </button>
                <span>

                </span>
                <span>
                  {t('Page')} {currentPage} {t('of')} {totalPages}
                </span>
                <span>

                </span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                  ›
                </button>
              </div>}
              </div>
         </div>
        </div>
      </div>
     
      <Footer />

      

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>


  );
}

export default MyLounges;