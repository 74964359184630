import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './planeblack.png';
import Cookies from 'js-cookie';

function Partner() {

  const { t } = useTranslation();

  const cardContainerRef = useRef(null)

  // const [insuretoken, setInsuretoken] = useState(null);
  // useEffect(() => {
  //     fetch('https://api.superjetom.com/create_inbound_token')
  //       .then(response => response.json())
  //       .then(data => {
  //         // debugger;
  //         setInsuretoken(data.result.data.token);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching countries:', error);
  //       });
  //   }, []);

  const [userData, setUserData] = useState();

  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString != undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    } else {
      // alert("token expired");
      // window.location.href = '/';
    }
  }, []);


  useEffect(() => {
    const container = cardContainerRef.current;
    let scrollInterval;

    if (container) {
      // Start an interval to scroll the container
      scrollInterval = setInterval(() => {
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += 1; 
        }
      }, 20); 

      return () => clearInterval(scrollInterval);
    }
  }, []);

  const resetData = () =>{
    const inboundDhofarData = Cookies.set('dhofarData', null);
    const outboundDhofarData = Cookies.set('dhofarDataoutbound', null); 
    const personalDhofarData = Cookies.set('personalData', null); 
    const healthDhofarData = Cookies.set('HealthIndividualData', null);
    const medicalDhofarData = Cookies.set('medicalData', null); 
    const medicalAffordableData = Cookies.set('medicalAffordableData', null); 
    const homeDhofarData = Cookies.set('homeData', null);  
  }
  const handleClick = () => {
    resetData();
    if (userData?.agency_name === null || userData?.agency_name === undefined || userData?.agency_name === '') {
    window.location.href = 'travel-insurance';
    }else{
      window.location.href = 'agent-travel-insurance';
    }
  };

  const handleClick2 = () => {
    resetData();
    window.location.href = 'personal-accident-insurance';

  };

  const handleClick4 = () => {
    resetData();
    window.location.href = 'medical-insurance';

};

const handleClick3 = () => {
  resetData();
window.location.href = 'life-accident-insurance';

};

const handleClick5 = () => {
  resetData(); 
window.location.href = 'cyber-plan';

};

const handleClick6 = () => {
  resetData();
  window.location.href = 'dhofar-motorinsurance';
  
  };

  const handleClick7 = () => {
    resetData();
    window.location.href = 'home-insurance';
    
    };

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  return (




    <div className="App">
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('INSURANCE')}</h3>
          </div>
        </div>
      </div>

      <div className="features_section layout_padding">
        <div className="container">
          <h1 className="features_text">
            {(lnstr === 'en') ? <div>{t('Our')} <span className="htext">{t('Products')}</span></div> : 'منتجاتنا'}
          </h1>
          <p className="text-center undertxt">{t('Offered by us to cover all your needs')}.</p>

          <div className="features_section2">

          
   
          <div className="row">

                       <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4  justify-content-center" style={{  cursor: 'pointer'}}  onClick={handleClick}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="service-content-inner">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{t('Travel Insurance')}</h5>
                    </div>
                  </div>
                  </div>
                
              </div>



              
            
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4  justify-content-center" style={{  cursor: 'pointer'  }}  onClick={handleClick2}>
              
                 <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4" style={{borderRadius : '0px 0px 10px 0px'}}>
                    <div className="personalaccident">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Personal Accident':'حادث شخصي'}</h5>
                    </div>
                  </div>
                  </div>
                </div>
              
                


              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4  justify-content-center"style={{  cursor: 'pointer' , marginRight: '0px'}} onClick={handleClick3}>

                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="life">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? '  Life Insurance  ':'التأمين على الحياة'}</h5>
                    </div>
                  
                  </div>
                </div>
              </div>
              

              
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 justify-content-center" style={{  cursor: 'pointer' , marginRight: '0px'}} onClick={handleClick4}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="medical_insurance">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Medical Insurance':'التأمين الطبي'}</h5>
                    </div>
                    </div>
                    
                  </div></div>

                  </div>
                
             
                  <div className="row mt-2">

                  {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4  justify-content-center"style={{  cursor: 'pointer' , marginRight: '0px' }} onClick={handleClick5}>
   <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="cyber">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Cyber Insurance':'التأمين على الحياة'}</h5>
                    </div>
                    </div>
                  </div>
                
              </div> */}


              <div className="col-sm-6 col-md-4 col-lg-3 mb-4 justify-content-center"style={{  cursor: 'pointer' , marginRight: '0px' }} onClick={handleClick6}>

<div className="service-item rounded">
  <div className="service-img rounded-top"></div>
  <div className="service-content rounded-bottom card bg-light p-4">
    <div className="motor">
      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Motor Insurance':'التأمين على السيارات'}</h5>
    </div>
  
  </div>
</div>
</div>




<div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4  justify-content-center" style={{  cursor: 'pointer'  }}  onClick={handleClick7}>
              
              <div className="service-item rounded">
               <div className="service-img rounded-top"></div>
               <div className="service-content rounded-bottom card bg-light p-4" style={{borderRadius : '0px 0px 10px 0px'}}>
                 <div className="home">
                   <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                   <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Home Insurance':'التأمين على المنزل'}</h5>
                 </div>
               </div>
               </div>
             </div>



                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">

                
              </div>



              <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
</div>








            </div>
            </div>
            </div>
            </div>
            
          
        
      
     
      <Footer />
    </div>
  );
}

export default Partner;

