import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');

function HotelDetails() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();
    const [vendorKey, setVendorkey] = useState('');
    const [loading, setLoading] = useState(false);
    const [adult, setAdultvalue] = useState(1)
    const [child, setChildvalue] = useState(0)

    const [hoteldetailsvalue, setHotelDetailsValue] = useState(null)
    const [photovalue, setPhotosvalue] = useState([]);
    const [days, setDaysValue] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [freefeature, setFreeFeatures] = useState([]);

    const [payfreature, setPayFeatures] = useState([])

    const [searchtitle, setSearchTitle] = useState(null);
    const [locationvalue, setLocationvalue] = useState([]);

    const [peoplecount , setpeoplecount] = useState(1);
     const [selectedGrossPrice, setSelectedGrossPrice] = useState('USD');

     const [totalprice , setTotalPrice] = useState([])
    //   useEffect(() => {

    //     const postvData = { organization_name: "Superjet" };
    //     debugger

    //     if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


    //       fetch( base_url + '/create_organization', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postvData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {
    //           setVendorkey(data.result[0].vendor_key);
    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });


    //     }
    //   }, [vendorKey]); // Dependency array

    useEffect(() => {
        setLoading(true)
        debugger
        const hotaldetailsjson = sessionStorage.getItem("hotelfulldetails");
        const details = JSON.parse(hotaldetailsjson);
        setHotelDetailsValue(details.fulldetails)
        setPhotosvalue(details.fulldetails.photos)
        setDaysValue(details.fulldetails.workingTime)
        setTitle(details.fulldetails.title)
        setAddress(details.fulldetails.address)
        setTotalPrice(details.fulldetails.variants)
        // setfeatures(details.fulldetails.features)

        const featuresvalue = details.fulldetails.features
        let free = []
        let pay = []

        featuresvalue.forEach((feature, index) => {
            if (feature.payable == false) {
                free.push(featuresvalue[index])
            } else {
                pay.push(featuresvalue[index])
            }
        });

        setFreeFeatures(free);
        setPayFeatures(pay);

        const location_json = sessionStorage.getItem("location");
        const locationdetails = JSON.parse(location_json)
        setLocationvalue(locationdetails.location)

        const searchdetailsjson = sessionStorage.getItem("hotaldetails");
        const searchdetails = JSON.parse(searchdetailsjson);

        setSearchTitle(searchdetails.searchtitle)
       
        setLoading(false)

    }, [])

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % hoteldetailsvalue.photos.length);
    };


    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + hoteldetailsvalue.photos.length) % hoteldetailsvalue.photos.length);
    };



    //   const [dhofarData, setDhofarData] = useState(false);
    //   const [age, setAge] = useState(null);
    //   useEffect(() => {


    //     const storedDhofarString = Cookies.get('personalData');

    //     if (storedDhofarString !== undefined) {
    //       const storedDhofar = JSON.parse(storedDhofarString);
    //       setDhofarData(storedDhofar);
    //     }
    //     else {
    //       alert("token expired");
    //       window.location.href='/';
    //     }

    //   }, []);


    //   const postData = { token: dhofarData };


    //   const [assure, setAssured] = useState('');
    //   const [premium, setpermium] = useState('');

    //   useEffect(() => {


    //     if (vendorKey !== '') {
    //       fetch(base_url + '/get_personal_accident_premium', {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': `Bearer ${vendorKey}`,
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postData),

    //       })
    //         .then(response => response.json())
    //         .then(data => {

    //           setpermium(data.result.data.premium);
    //           setAssured(data.result.data.sum_assured);

    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });

    //     }
    //   }, [vendorKey]);

    //   //getmaritalstatus


    //   useEffect(() => {
    //     debugger

    //     const preminumjson = sessionStorage.getItem('personalaccidentpremiumcover');

    //     if (preminumjson!=undefined){
    //       debugger
    //       const preminums = JSON.parse(preminumjson);
    //       setpermium(preminums.premium_valu);
    //       setAssured(preminums.assured_value);
    //     }


    //   },[]);






    //   const handleSubmit = async (event) => {

    //     var data = {
    //       "premium_valu": premium,
    //       "assured_value": assure
    //     }

    //     debugger

    //     sessionStorage.setItem("personalaccidentpremiumcover", JSON.stringify(data))

    //     event.preventDefault();
    //     setLoading(true);

    //     event.preventDefault();
    //     window.location.href = '/add-beneficiary';



    //   };

    //   const [buttonDisabled, setButtonDisabled] = useState(false);

    //   // const today = new Date().toISOString().split('T')[0];
    //   const today = new Date();
    //   const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


    //   const [dobValue, setdobValue] = useState('');
    //   const handledobChange = (event) => {
    //     const selectedDob = event.target.value;
    //     setdobValue(event.target.value);

    //     const dobDate = new Date(selectedDob);
    //     const today = new Date();
    //     let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    //     const monthDiff = today.getMonth() - dobDate.getMonth();

    //     // Adjust age if the current date is before the user's birthday in the current year
    //     if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
    //       calculatedAge--;
    //     }

    //     setAge(calculatedAge);
    //   };

    //   const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    //   const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    //   const handlegenderChange = (event) => {
    //     setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    //     setSelectedgenderOption(event.target.value);
    //   };

    //   const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
    //   const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
    //   const handlemaritalChange = (event) => {
    //     setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    //     setSelectedmaritalOption(event.target.value);
    //   };
    //   //getgender
    //   const NumberFormatter = ({ value }) => {
    //     // Ensure value is a number and format to 3 decimal places
    //     const formattedValue = `${Number(value).toFixed(3)} OMR`;
    //   debugger
    //     return formattedValue ;
    //   };  

    const handlesubmit = (value) => {
        if (adult < 0 && value == -1) {
            setAdultvalue(0)
        } else {
            var count = value + adult
            setAdultvalue(count)
            var totalcount = peoplecount + value
            setpeoplecount(totalcount)
        }
    }


    const handlesubmit1 = (value) => {
        debugger
        if (child < 0 && value == -1) {
            setChildvalue(0)
        } else {
            var count = value + child
            setChildvalue(count)

            var totalcount = peoplecount + value
            setpeoplecount(totalcount)


        }

        
    }

    const getGrossPriceForCurrency = (totalprice , selectedCurrency) => {
        debugger
        const currencyData = totalprice[0]?.adultPrice?.currencies?.find(currency => currency.currency === selectedCurrency);
        if (currencyData) {

            const splitValue = Math.floor(currencyData?.grossPrice)
            const totalvalue = peoplecount * splitValue 
            return totalvalue;
        }
        // If currency not found, return the default gross price (USD or fallback)
        const totalgrocssprice = peoplecount * totalprice[0]?.adultPrice?.grossPrice
        return totalgrocssprice;
    };


    const getGrossPriceoneperson = (totalprice , selectedCurrency) => {
        debugger
        const currencyData = totalprice[0]?.adultPrice?.currencies?.find(currency => currency.currency === selectedCurrency);
        if (currencyData) {

            const splitValue = Math.floor(currencyData?.grossPrice) 
            return splitValue;
        }
        // If currency not found, return the default gross price (USD or fallback)
        const totalgrocssprice = totalprice[0]?.adultPrice?.grossPrice
        return totalgrocssprice;
    };

    const handleMapLocation = () => {

        if (locationvalue){
            debugger
        var policyLink = `https://www.google.com/maps/search/?api=1&query=${locationvalue.latitude},${locationvalue.longitude}`
        var url = policyLink;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = url.split('/').pop(); // Extract filename from URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
        
    }

    const handleselect = () =>{
        setLoading(true)
        var data = {
            'adult': adult,
            'child': child,
            'total': peoplecount
        }

        sessionStorage.setItem('personalcountvalue',JSON.stringify(data));
        setLoading(false)

        window.location.href = '/lounge-personaldetails'
    }







    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Lounge Details' : 'تفاصيل الصالة\n'}</h3>
                    </div>

                    {/* <div className="pt-5">

                    <select className="col-md-1 form-control" style={{ borderRadius: '1.5rem' }} onChange={(event)=>{
                        setSelectedGrossPrice(event.target.value)
                    }}>
                        {totalprice.map((value, outindex) => {
                            if (outindex === 0) {
                                return (
                                    <>
                                        <option>
                                            {value.adultPrice.baseCurrency}
                                        </option>
                                        {value.adultPrice.currencies.map((val, index) => (
                                            <option key={index}>{val.currency}</option>
                                        ))}
                                    </>
                                );
                            }
                            return null;
                        })}
                    </select>
                        
                    </div> */}

                </div>
            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={1}></Col>

                        <Col md={10} className="myforms my-5">


                            <div className="card" style={{ borderTopLeftRadius: '1.5rem', borderTopRightRadius: '1.5rem' }}>

                                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        {photovalue.map((photo, index) => (
                                            <div
                                                // key={slide.id}
                                                className={`carousel-item ${activeIndex === index ? 'active' : ''}`}
                                            >
                                                <img
                                                    style={{ borderTopRightRadius: '1.5rem', borderTopLeftRadius: '1.5rem', width: '1000px', height: '500px', objectFit: 'cover' }}
                                                    key={index}
                                                    src={photo}
                                                    alt={`Hotel photo ${index + 1}`}
                                                // style={{ borderRadius: '1.5rem'}}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <a
                                        className="carousel-control-prev"
                                        // href="#carouselExampleControls"
                                        role="button"
                                        onClick={handlePrev}
                                    >
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a
                                        className="carousel-control-next"
                                        // href="#carouselExampleControls"
                                        role="button"
                                        onClick={handleNext}
                                    >
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>





                                {/* <img src={hoteldetailsvalue.photos[0]}alt=".." style={{ borderRadius: '1.5rem' }} /> */}
                                {/* <h1>{hoteldetailsvalue.photos[0]}</h1> */}
                                <div className="card-body">
                                    <div className="card-tittle">
                                        <h1><strong>{title}</strong></h1>
                                        <div>
                                            <p>{searchtitle}</p>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary" onClick={() => handleMapLocation()}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg><span style={{ marginLeft: '3px' }}>View On Map</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" >

                                <div className=" container  pt-5">
                                    <div className="row container d-flex justify-content-between">
                                    <div>
                                        <h3><strong>{hoteldetailsvalue?.terminal?.title}</strong></h3>
                                    </div>
                                    <div>
                                        <h3><strong>{hoteldetailsvalue?.terminalType[0].charAt(0).toUpperCase() + hoteldetailsvalue?.terminalType[0].slice(1).toLowerCase()} | {hoteldetailsvalue?.gateType.charAt(0).toUpperCase() + hoteldetailsvalue?.gateType.slice(1).toLowerCase()}</strong></h3>
                                    </div>
                                    </div>
                                </div>
                                <hr style={{ width: 'auto' }} />
                                <div className="card-body">
                                    <div>
                                        <h3><strong>Location</strong></h3>
                                        <div>
                                            <p>{address}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="card container">
                                <div>
                                    <h3 className="pt-3"><strong>Always open</strong></h3>
                                    <hr />
                                    <h4>Working hours</h4>
                                </div>
                                <div className="card-body">
                                    {days.map((slot, index) => (
                                        <div>
                                            <div className="d-flex justify-content-between" key={index}>
                                                <div>
                                                    <h4>{slot.day.charAt(0).toUpperCase() + slot.day.slice(1).toLowerCase()}</h4>
                                                </div>
                                                <div>
                                                    <h4>{slot.open} - {slot.close}</h4>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div>

                            <div className="card pt-3">
                                <div style={{ margin: "20px" }}>
                                    <h3><strong>Free features</strong></h3>

                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "repeat(2, 1fr)",
                                            gap: "10px",
                                            //   maxWidth: "400px",
                                        }}
                                    >
                                        {freefeature.map((feature) => (
                                            <div
                                                key={feature.id}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    border: "1px solid #c1c8cd",
                                                    borderRadius: "8px",
                                                    padding: "10px",
                                                    backgroundColor: "#ffffff",
                                                    color: "black",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                <svg

                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="#C1C8CD"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    style={{ marginRight: "8px" }}
                                                >
                                                    {/* <path d='da9098ab-103d-48ff-bcee-8462868281b5'></path> */}
                                                </svg>
                                                {feature.title}
                                            </div>
                                        ))}
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className="card-boby">
                                    <div className="card-title" style={{ margin: "20px" }}>
                                        <h3><strong>Optional</strong></h3>

                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                                gap: "10px",
                                                //   maxWidth: "400px",
                                            }}
                                        >

                                            {payfreature.map((pay) => (

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        border: "1px solid #c1c8cd",
                                                        borderRadius: "8px",
                                                        padding: "10px",
                                                        backgroundColor: "#D7DBDF",
                                                        // color: "white",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <svg

                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#C1C8CD"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        style={{ marginRight: "8px" }}
                                                    >
                                                        {/* <path d="da9098ab-103d-48ff-bcee-8462868281b5" /> */}

                                                    </svg>

                                                    {pay.title}

                                                </div>

                                            ))}


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card container pt-3">
                                <div>
                                    <h3><strong>Number of visitors</strong></h3>
                                </div>
                                <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                                    <div className="d-flex justify-content-between my-1">
                                        <div >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                            </svg> <strong style={{ fontSize: '20px' }}>Adult</strong> <p>Over 12 years old</p>
                                        </div>
                                        <div className="pt-3">
                                            <div className="d-flex justify-content-between">
                                                <div style={{ marginRight: '20px' }}>
                                                    <button className="btn btn-primary" disabled={ adult == 0 }  onClick={() => handlesubmit(-1)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div style={{ marginRight: '20px' }}>
                                                    <h1>{adult}</h1>
                                                </div>
                                                <div>
                                                    <button className="btn btn-primary" disabled={ peoplecount == 10}   onClick={() => handlesubmit(1)}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>






                                <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                                    <div className="d-flex justify-content-between my-1">
                                        <div >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                            </svg> <strong style={{ fontSize: '20px' }}>Child</strong> <p>Up to 12 years old</p>
                                        </div>
                                        <div className="pt-3">
                                            <div className="d-flex justify-content-between">
                                                <div style={{ marginRight: '20px' }}>
                                                    <button className="btn btn-primary" disabled = {child == 0} onClick={() => handlesubmit1(-1)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div style={{ marginRight: '20px' }}>
                                                    <h1>{child}</h1>
                                                </div>
                                                <div>
                                                    <button className="btn btn-primary" disabled = {peoplecount == 10} onClick={() => handlesubmit1(1)}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <p>Children <strong>under 2 years </strong>old are admitted free with an adult</p>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="col-6 mb-4">
                                            <div >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                                </svg> <strong style={{ fontSize: '20px' }}>Adult</strong> <p>Cancellation is not permitted</p>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-4">
                                            <div >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                                </svg> <strong style={{ fontSize: '20px' }}>Child</strong> <p>Cancellation is not permitted</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body container">
                                    <h3>Stay duration per 1 person</h3>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <button style={{width:'250px'}} >
                                                    <h4 style={{color:'white'}}><strong>
                                                        {hoteldetailsvalue?.variants[0]?.value} {hoteldetailsvalue?.variants[0]?.type}
                                                        <h4 style={{color:'white'}}>
                                                        {selectedGrossPrice === 'USD'
                                                ? totalprice[0]?.adultPrice?.grossPrice 
                                                : getGrossPriceoneperson(totalprice, selectedGrossPrice)}{selectedGrossPrice}
                                                        </h4>
                                                        </strong></h4>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {peoplecount > 0 && (
                                <div className="card">
                            
                                <div className="card-body">
                                <div className="d-flex justify-content-center">

                                    <div>
                                    <h3 className="text-center py-0"><strong>{selectedGrossPrice === 'USD' 
                                                ? peoplecount * totalprice[0]?.adultPrice?.grossPrice 
                                                : getGrossPriceForCurrency(totalprice, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                    <span>{adult} {adult > 1 ? 'adults' : 'adult'} {child > 0 && (<span>| {child} {child > 1 ? 'children':'child'}</span>)} <span> | {hoteldetailsvalue?.variants[0]?.value} {hoteldetailsvalue?.variants[0]?.type} </span></span>
                                    </div>
                                    <div>
                                    <button className="btn btn-primary" type="submit" style={{marginLeft:'20px'}} onClick={()=>handleselect()}>
                                        Select
                                    </button>

                                    </div>
                                </div>
                                </div>
                            </div>

                            )}
                            








                        </Col>

                        <Col md={1}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>
    );
}

export default HotelDetails;
