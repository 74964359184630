import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format, set } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');

function PersonalDetails() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();
    const [vendorKey, setVendorkey] = useState('');
    const [loading, setLoading] = useState(false);
    const [adult, setAdultvalue] = useState(1)
    const [child, setChildvalue] = useState(0)
    const [title, setTitle] = useState('');
    const [userData, setUserData] = useState();

    const [hoteldetailsvalue, setHotelDetailsValue] = useState(null)
    const [peoplename, setPeopleName] = useState([]);
    const [searchtitle, setSearchTitle] = useState(null);

    const [peoplecount, setpeoplecount] = useState(1);
    const [peoplecounts, setpeoplecounts] = useState({ adult: 0, child: 0 });
    const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
    const [totalamount, settotalvalue] = useState(null)

    const [doberror, setErrorDOB] = useState('')

    const [selectedGrossPrice, setSelectedGrossPrice] = useState('USD');

    const [totalprice, setTotalPrice] = useState([]);
    const [variantid, setVariantId] = useState(null);
    const [datahash, setDataHash] = useState(null)

    const [nameerror, setFirstname] = useState('');
    const [surnameerror, setSurname] = useState('');
    const [emailerror , setEmailError] = useState('');
    useEffect(() => {

        const postvData = { organization_name: "Superjet" };
        debugger

        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


            fetch(base_url + '/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {
                    setVendorkey(data.result[0].vendor_key);
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });


        }
    }, [vendorKey]); // Dependency array
    useEffect(() => {
        const storedUserString = Cookies.get('userData');
        if (storedUserString !== undefined) {
          const user_data = JSON.parse(storedUserString);
        
          setUserData(user_data);
          
          
        } else {
        //   alert('Login Session Expired');
        //   window.location.href = '/';
        }
    
      }, [userData, vendorKey]);

    useEffect(() => {
        debugger
        const hotaldetailsjson = sessionStorage.getItem("hotelfulldetails");
        const details = JSON.parse(hotaldetailsjson);
        setHotelDetailsValue(details.fulldetails)
        setTitle(details.fulldetails.title)
        setTotalPrice(details.fulldetails.variants)
        setVariantId(details.fulldetails.variants[0].id)
        setDataHash(details.fulldetails.variants[0].dataHash)
        const searchdetailsjson = sessionStorage.getItem("hotaldetails");
        const searchdetails = JSON.parse(searchdetailsjson);
        setSearchTitle(searchdetails.searchtitle)

        const peoplevaluejson = sessionStorage.getItem("personalcountvalue");
        const peopledetails = JSON.parse(peoplevaluejson);

        setAdultvalue(peopledetails.adult)
        setChildvalue(peopledetails.child)
        setpeoplecount(peopledetails.total)

        //     const newPeopleFormRows = Array.from({ length: peopledetails.total }, () => ({
        //         firstName: '',
        //         lastName: '',
        //         email: '',
        //         date:'',
        //         paxType:'',
        //         dataHash:'',
        //         variantId:''

        // // "variantId": "PV-000000518",
        // // "paxType": "adult",
        // // "firstName": "Prabu",
        // // "lastName": "Thiruppathi",
        // // "date": "2025-01-31",
        // // "dataHash": "a19e068f5afbdc05b9ee89de4ce4cbe3abd5b7b43938ad52dcae49ca086b093d",
        // // "email": "prabu@nigsoft.com"
        //     }));
        debugger
        var newPeopleFormRows = [];

        if (peopledetails.child > 0) {
            newPeopleFormRows = newPeopleFormRows.concat(
                Array.from({ length: peopledetails.child }, () => ({
                    firstName: '',
                    lastName: '',
                    email: '',
                    date: '',
                    paxType: 'child',
                    dataHash: details.fulldetails.variants[0].dataHash,
                    variantId: details.fulldetails.variants[0].id
                }))
            );
        }

        if (peopledetails.adult > 0) {
            newPeopleFormRows = newPeopleFormRows.concat(
                Array.from({ length: peopledetails.adult }, () => ({
                    firstName: '',
                    lastName: '',
                    email: '',
                    date: '',
                    paxType: 'adult',
                    dataHash: details.fulldetails.variants[0].dataHash,
                    variantId: details.fulldetails.variants[0].id
                }))
            );
        }

        setPeopleFormRows(newPeopleFormRows);

    }, [])

    const currentdate = new Date().toISOString().split('T')[0];
    const handlesubmit = (value, type) => {
        debugger
        if (adult < 0 && value == -1) {
            setAdultvalue(0)
        } else {
            var count = value + adult
            setAdultvalue(count)
            var totalcount = peoplecount + value
            setpeoplecount(totalcount)
            setpeoplecounts({ adult: totalcount, child: child });
        }
    }


    const handlesubmit1 = (value, type) => {
        debugger
        if (child < 0 && value == -1) {
            setChildvalue(0)
        } else {
            var count = value + child
            setChildvalue(count)

            var totalcount = peoplecount + value
            setpeoplecount(totalcount)
            setpeoplecounts({ adult: adult, child: totalcount });

        }


    }


    const [peopleformrows, setPeopleFormRows] = useState([{
        firstName: '',
        lastName: '',
        email: '',
        date: '',
        paxType: '',
        dataHash: datahash,
        variantId: variantid,
    }]);


    const handlepeopleInputChange = (index, event, field) => {
        const updatedRows = [...peopleformrows];
        debugger
        // Check if event.target exists and if the field is valid
        if (event.target && field) {
            updatedRows[index][field] = event.target.value;
        }
        console.log('Index:', index);
        console.log('Field:', field);
        console.log('Updated Rows:', updatedRows);

        // Update the state with the new array of rows
        setPeopleFormRows(updatedRows);
    };



    const handlePeopleAddRow = (event, type) => {

        setPeopleFormRows([...peopleformrows, {
            firstName: '',
            lastName: '',
            email: '',
            date: '',
            paxType: type,
            dataHash: datahash,
            variantId: variantid

        }]);
    };



    const handlePeopleRemoveRow = (index) => {
        setPeopleFormRows(peopleformrows.filter((_, i) => i !== index));
    };

    const validationform = () => {
        let isValid = true;
    
        const countName = document.getElementsByName('firstName');
        const countSurname = document.getElementsByName('lastName');
        const countEmail = document.getElementsByName('email');
        
        // Assuming 'peopleformrows' is an array of form row objects
        const updatedRows = [...peopleformrows];
    
        for (let index = 0; index < updatedRows.length; index++) {
            let row = updatedRows[index];
            const nameValue = row.firstName;
            const surnameValue = row.lastName;
            const emailValue = row.email;
    
            // Validate first name
            if (nameValue === '') {
                countName[index].focus();
                setFirstname('Please enter a valid name');
                isValid = false;
                break;
            } else if (nameValue.length < 4) {
                countName[index].focus();
                setFirstname('Name should be at least 4 characters long');
                isValid = false;
                break;
            }
    
            // Validate surname
            if (surnameValue === '') {
                countSurname[index].focus();
                setSurname('Please enter a valid surname');
                isValid = false;
                break;
            } else if (surnameValue.length < 4) {
                countSurname[index].focus();
                setSurname('Surname should be at least 4 characters long');
                isValid = false;
                break;
            }
    
            // Validate email (first row should have email check)
            if (index === 0) {
                if (emailValue === '') {
                    countEmail[index].focus();
                    setEmailError('Email is required');
                    isValid = false;
                    break;
                } else {
                    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    if (!emailPattern.test(emailValue)) {
                        countEmail[index].focus();
                        setEmailError('Please enter a valid email address. Example: example@domain.com');
                        isValid = false;
                        break;
                    }
                }
            }
        }
    
        return isValid;
    };
    

    const getGrossPriceForCurrency = (totalprice, selectedCurrency) => {
        debugger
        const currencyData = totalprice[0]?.adultPrice?.currencies?.find(currency => currency.currency === selectedCurrency);
        if (currencyData) {

            const splitValue = Math.floor(currencyData?.grossPrice)
            const totalvalue = peoplecount * splitValue
            // settotalvalue(totalvalue)
            return totalvalue;
        }
        // If currency not found, return the default gross price (USD or fallback)
        const totalgrocssprice = peoplecount * totalprice[0]?.adultPrice?.grossPrice
        // settotalvalue(totalgrocssprice)
        return totalgrocssprice;
    };

    const getGrossPriceoneperson = (totalprice , selectedCurrency) => {
        debugger
        const currencyData = totalprice[0]?.adultPrice?.currencies?.find(currency => currency.currency === selectedCurrency);
        if (currencyData) {

            const splitValue = Math.floor(currencyData?.grossPrice) 
            return splitValue;
        }
        // If currency not found, return the default gross price (USD or fallback)
        const totalgrocssprice = totalprice[0]?.adultPrice?.grossPrice
        return totalgrocssprice;
    };



    const renderVisitorPeople = (index, row, handlepeopleInputChange) => {
        return (
            <div className="card" key={index}>
                <div className="card-body">
                    <h3><strong>Visitor {index + 1} {row.paxType} details</strong></h3>
                    <div className="d-flex justify-content-between">
                        <div className="col-md-6">
                            <h5>Name</h5>
                            <input
                                className="form-control"
                                type="text"
                                name="firstName"
                                onChange={(e) => {
                                    handlepeopleInputChange(index, e, 'firstName')
                                    setFirstname('')
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                }}
                                value={row.name}
                                minLength={3}
                                required
                            />
                            {nameerror && (<p style={{ color: 'red' }}>{nameerror}</p>)}
                        </div>
                        <div className="col-md-6">
                            <h5>Surname</h5>
                            <input
                                className="form-control"
                                type="text"
                                name="lastName"
                                onChange={(e) => {
                                    handlepeopleInputChange(index, e, 'lastName')
                                    setSurname('')
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                }}
                                value={row.surname}
                                minLength={3}
                                required
                            />
                            {surnameerror && (<p style={{ color: 'red' }}>{surnameerror}</p>)}
                        </div>
                    </div>

                    {index === 0 && (
                        <div className="col-md-6 pt-2">
                            <h5>Email of confirmation</h5>
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                onChange={(e) => {handlepeopleInputChange(index, e, 'email')
                                    setEmailError('')
                                }}
                                // onInput={(e) => {
                                //     e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                // }}
                                value={row.email}
                            />
                            {emailerror && (<p style={{color:'red'}}>{emailerror}</p>)}
                        </div>
                    )}
                </div>
            </div>
        );
    };



    // const handlenextpage = (newDate, total) => {
    //     debugger
    //     const emailvalue = peopleformrows[0].email
    //     const date = newDate
    //     // setPeopleFormRows(peopleformrows => peopleformrows.map(row => ({
    //     //     ...row,
    //     //     date: date,
    //     //     email: emailvalue,
    //     // })));


    //     if (emailvalue && date) {
    //         setPeopleFormRows(peopleformrows => {
    //             const updatedRows = peopleformrows.map(row => ({
    //                 ...row,
    //                 date: date,
    //                 email: emailvalue,
    //             }));

    //             console.log("After Update:", updatedRows);
    //             return updatedRows;
    //         });
    //     } else {
    //         console.error('Error: email or date is invalid');
    //     }

    //     // console.log('Updating with date:', date);

    //     // setPeopleFormRows(prevRows => {
    //     //     const updatedRows = [...prevRows];
    //     //     updatedRows[index] = {
    //     //         ...updatedRows[index],
    //     //         date: newDate,
    //     //         email: newEmail,
    //     //     };
    //     //     return updatedRows;
    //     // });
    //     if (selectedDateofBirth == null) {
    //         document.getElementsByName("dob")[0].focus();
    //     } else if (selectedDateofBirth < currentdate) {
    //         document.getElementsByName("dob")[0].focus();
    //         setErrorDOB("Please enter the furture date")
    //     } else if (!validationform()) {
    //         return
    //     } else {

    //         setLoading(true)

    //         const searchdetailsjson = sessionStorage.getItem("hotaldetails");
    //         const searchdetails = JSON.parse(searchdetailsjson);

    //         const hotaldetailsjson = sessionStorage.getItem("hotelfulldetails");
    //         const details = JSON.parse(hotaldetailsjson);

    //         var apidata = {
    //             "contract_id": searchdetails.contract_id,
    //             "adult_count": adult,
    //             "child_count": child,
    //             "total": total,
    //             "currency": selectedGrossPrice,
    //             "terminal": hoteldetailsvalue?.terminal?.title,
    //             "product_value": hoteldetailsvalue?.variants[0]?.value,
    //             "product_type": hoteldetailsvalue?.variants[0]?.type,
    //             "visit_date": selectedDateofBirth,
    //             "airport_code": searchdetails.query,
    //             "airport_name": searchtitle,
    //             "variant_id": hoteldetailsvalue?.variants[0]?.id,
    //             "terminal_data": JSON.stringify(details.fulldetails),
    //             "order_data": peopleformrows,

    //         }

    //         if (vendorKey !== '') {
    //             fetch(base_url + '/create_lounge_order', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Authorization': `Bearer ${vendorKey}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify(apidata),

    //             })
    //                 .then(response => response.json())
    //                 .then(data => {

    //                     // setcontractsID(data.result);
    //                     const result = data?.result
    //                     alert(data?.message)
    //                     setLoading(false)

    //                 })
    //                 .catch(error => {
    //                     console.error('Error posting data:', error);
    //                 });

    //         }
    //     }
    // }
    const handlenextpage = async (newDate, total) => {
        debugger;
        const emailvalue = peopleformrows[0]?.email;
        const date = newDate;
    
        if (!emailvalue || !date) {
            console.error('Error: email or date is invalid');
           
        }
    
     
            // const updatedRows = peopleformrows.map(row => ({
            //     ...row,
            //     date: date,
            //     email: emailvalue,
            // }));
            // console.log("After Update:", updatedRows);
     
      
    
        if (selectedDateofBirth == null) {
            document.getElementsByName("dob")[0]?.focus();
        } else if (selectedDateofBirth < currentdate) {
            document.getElementsByName("dob")[0]?.focus();
            setErrorDOB("Please enter a future date");
        } else if (!validationform()) {
            return; // Stop execution if form is not valid
        } else {
            setLoading(true);
    
            const searchdetailsjson = sessionStorage.getItem("hotaldetails");
            const searchdetails = searchdetailsjson ? JSON.parse(searchdetailsjson) : {};
            
            const hotaldetailsjson = sessionStorage.getItem("hotelfulldetails");
            const details = hotaldetailsjson ? JSON.parse(hotaldetailsjson) : {};
            const updatedRows = peopleformrows.map(row => ({
                ...row,
                date: date,
                email: emailvalue,
            }));
            console.log("After Update:", updatedRows);
            var url = '';
            var apidata='';
            debugger
            // traveller
            if (userData != null && userData != undefined && userData.traveller_id != undefined) {
            // if ( (userData !== null && userData?.traveller_id !== null)|| (userData !== undefined && userData?.traveller_id !== undefined) || (userData !== '' && userData?.traveller_id !== '')) {
                url = base_url + '/create_lounge_order_user_user';
                apidata = {
                   "token":userData?.access_token,
                   "contract_id": searchdetails.contract_id,
                   "adult_count": adult,
                   "child_count": child,
                   "total": total,
                   "currency": selectedGrossPrice,
                   "terminal": hoteldetailsvalue?.terminal?.title,
                   "product_value": hoteldetailsvalue?.variants[0]?.value,
                   "product_type": hoteldetailsvalue?.variants[0]?.type,
                   "visit_date": selectedDateofBirth,
                   "airport_code": searchdetails.query,
                   "airport_name": searchtitle,
                   "variant_id": hoteldetailsvalue?.variants[0]?.id,
                   "terminal_data": JSON.stringify(details.fulldetails),
                   "order_data": updatedRows,
               };
               

            }else{
               
                

               url = base_url + '/create_lounge_order';
               apidata = {
                  "contract_id": searchdetails.contract_id,
                  "adult_count": adult,
                  "child_count": child,
                  "total": total,
                  "currency": selectedGrossPrice,
                  "terminal": hoteldetailsvalue?.terminal?.title,
                  "product_value": hoteldetailsvalue?.variants[0]?.value,
                  "product_type": hoteldetailsvalue?.variants[0]?.type,
                  "visit_date": selectedDateofBirth,
                  "airport_code": searchdetails.query,
                  "airport_name": searchtitle,
                  "variant_id": hoteldetailsvalue?.variants[0]?.id,
                  "terminal_data": JSON.stringify(details.fulldetails),
                  "order_data": updatedRows,
              };

            }
    
        
    
            if (vendorKey) {
                debugger
               await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${vendorKey}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(apidata),
                })
                    .then(response => response.json())
                    .then(data => {
                        debugger
                        var result = data?.result;
                        debugger
                        // alert(data?.message);
                        var data = {
                            'fulldetails':result,
                            'personaldetail':updatedRows[0]
                        }
                        sessionStorage.setItem('personaldetails',JSON.stringify(data))
                        window.location.href = '/lounge-payment'
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error posting data:', error);
                        setLoading(false); // In case of error, stop loading
                    });
            } else {
                console.error('Error: vendorKey is missing or invalid');
            }
        }
    };
    





    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Details' : 'التفاصيل الشخصية\n'}</h3>
                    </div>
                    <div className="pt-5">

                        <select className="col-md-1 form-control" style={{ borderRadius: '1.5rem' }} onChange={(event) => {
                            setSelectedGrossPrice(event.target.value)
                        }}>
                            {totalprice.map((value, outindex) => {
                                if (outindex === 0) {
                                    return (
                                        <>
                                            <option>
                                                {value.adultPrice.baseCurrency}
                                            </option>
                                            {value.adultPrice.currencies.map((val, index) => (
                                                <option key={index}>{val.currency}</option>
                                            ))}
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </select>

                    </div>
                </div>
            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={1}></Col>

                        <Col md={10} className="myforms my-5">

                            <div className="card" >

                                <div className="d-flex justify-content-between colmds-4 card m-0 ">

                                    <h1><strong>{title}</strong></h1>
                                    <div>
                                        <p>{searchtitle}</p>
                                    </div>


                                </div>

                                <div className=" container pt-5">
                                    <div className="row container d-flex justify-content-between">
                                    <div>
                                        <h3><strong>{hoteldetailsvalue?.terminal?.title}</strong></h3>
                                    </div>
                                    <div>
                                        <h3><strong>{hoteldetailsvalue?.terminalType[0].charAt(0).toUpperCase() + hoteldetailsvalue?.terminalType[0].slice(1).toLowerCase()} | {hoteldetailsvalue?.gateType.charAt(0).toUpperCase() + hoteldetailsvalue?.gateType.slice(1).toLowerCase()}</strong></h3>
                                    </div>
                                    </div>


                                </div>

                            </div>

                            <div className="card container pt-3">
                                <div>
                                    <h3><strong>Booking information</strong></h3>
                                    <label><strong>Airport local time</strong></label>
                                    <input className="form-control col-md-6" type="date" name="dob" min={currentdate} onChange={(e) => {
                                        setSelectedDateofBirth(e.target.value);
                                        setErrorDOB('')
                                    }} />
                                    {doberror && (
                                        <p style={{ color: 'red' }}>{doberror}</p>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                                    <div className="d-flex justify-content-between my-1">
                                        <div >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                            </svg> <strong style={{ fontSize: '20px' }}>Adult</strong> <p>Over 12 years old</p>
                                        </div>
                                        <div className="pt-3">
                                            <div className="d-flex justify-content-between">
                                                <div style={{ marginRight: '20px' }}>
                                                    <button className="btn btn-primary" disabled={adult == 0} onClick={() => {
                                                        handlesubmit(-1, 'adult')
                                                        handlePeopleRemoveRow(peoplecount - 1,)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div style={{ marginRight: '20px' }}>
                                                    <h1>{adult}</h1>
                                                </div>
                                                <div>
                                                    <button className="btn btn-primary" disabled={peoplecount == 10} onClick={(e) => {
                                                        handlesubmit(1, 'adult')
                                                        handlePeopleAddRow(e, 'adult')
                                                    }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>






                                <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                                    <div className="d-flex justify-content-between my-1">
                                        <div >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                            </svg> <strong style={{ fontSize: '20px' }}>Child</strong> <p>Up to 12 years old</p>
                                        </div>
                                        <div className="pt-3">
                                            <div className="d-flex justify-content-between">
                                                <div style={{ marginRight: '20px' }}>
                                                    <button className="btn btn-primary" disabled={child == 0} onClick={() => {
                                                        handlesubmit1(-1, 'child')
                                                        handlePeopleRemoveRow(peoplecount - 1)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div style={{ marginRight: '20px' }}>
                                                    <h1>{child}</h1>
                                                </div>
                                                <div>
                                                    <button className="btn btn-primary" disabled={peoplecount == 10} onClick={(e) => {
                                                        handlesubmit1(1, 'child')
                                                        handlePeopleAddRow(e, 'child');


                                                    }
                                                    }><svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <p>Children <strong>under 2 years </strong>old are admitted free with an adult</p>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="col-6 mb-4">
                                            <div >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                                </svg> <strong style={{ fontSize: '20px' }}>Adult</strong> <p>Cancellation is not permitted</p>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-4">
                                            <div >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                                </svg> <strong style={{ fontSize: '20px' }}>Child</strong> <p>Cancellation is not permitted</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body container">
                                    <h3>Stay duration per 1 person</h3>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                            <button style={{width:'250px'}} >
                                                    <h4 style={{color:'white'}}><strong>
                                                        {hoteldetailsvalue?.variants[0]?.value} {hoteldetailsvalue?.variants[0]?.type}
                                                        <h4 style={{color:'white'}}>
                                                        {selectedGrossPrice === 'USD'
                                                ? totalprice[0]?.adultPrice?.grossPrice 
                                                : getGrossPriceoneperson(totalprice, selectedGrossPrice)}{selectedGrossPrice}
                                                        </h4>
                                                        </strong></h4>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {peopleformrows.length > 0 && peopleformrows.map((row, index) => (
                                renderVisitorPeople(index, row, handlepeopleInputChange)
                            ))}


                            {peoplecount > 0 && (
                                <div className="card">

                                    <div className="card-body">

                                        <div className="d-flex justify-content-between">
                                            <div><h2><strong>{title}</strong></h2>
                                            </div>
                                            {/* <div> <strong>* {peoplecount}</strong></div> */}

                                            <div><h3 className="text-center py-0"><strong>{selectedGrossPrice === 'USD'
                                                ? peoplecount * totalprice[0]?.adultPrice?.grossPrice
                                                : getGrossPriceForCurrency(totalprice, selectedGrossPrice)}{selectedGrossPrice}</strong></h3></div>
                                        </div>

                                        <hr />
                                        <div>
                                            <p>Guests should ensure that lounge location corresponds to the specific airport or terminal area from which their flight is scheduled to depart/arrive and they have all required travel documents to pass airport controlled zone (immigration and customs procedures, security etc.)</p>
                                        </div>
                                        <div className="d-flex justify-content-center pt-5">

                                            <div>
                                                <h3 className="text-center py-0"><strong>{selectedGrossPrice === 'USD'
                                                    ? peoplecount * totalprice[0]?.adultPrice?.grossPrice
                                                    : getGrossPriceForCurrency(totalprice, selectedGrossPrice)}{selectedGrossPrice}</strong></h3>
                                                <span>{adult} {adult > 1 ? 'adults' : 'adult'} {child > 0 && (<span>| {child} {child > 1 ? 'children' : 'child'}</span>)} <span> | {hoteldetailsvalue?.variants[0]?.value} {hoteldetailsvalue?.variants[0]?.type} </span></span>
                                            </div>

                                        </div>

                                        <div className="text-center">
                                            <button className="btn btn-primary text-center" type="submit" style={{ marginLeft: '20px', width: '100px' }} onClick={() =>
                                                handlenextpage(
                                                    selectedDateofBirth,
                                                    selectedGrossPrice === 'USD'
                                                        ? peoplecount * totalprice[0]?.adultPrice?.grossPrice
                                                        : getGrossPriceForCurrency(totalprice, selectedGrossPrice)
                                                )
                                            }>
                                                CheckOut
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            )}









                        </Col>

                        <Col md={1}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>


    );


}

export default PersonalDetails;
